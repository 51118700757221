import React, { Component } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Button from '../../Components/Button/Button'
import Logo from '../../Components/Logo/Logo'
import { OffCanvasNav } from '../../Components/OffCanvasNav/OffCanvasNav'


export default class Nav extends Component {
    state = {
        stickyNav: false,
        showOffCanvasNav: false
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.scrollHandler);
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    scrollHandler = () => {
        if (window.pageYOffset > 400) {
            this.setState({stickyNav: true})
        } else {
            this.setState({stickyNav: false})
        }
    }

    onHomeClick = () => {
        window.scrollTo(0,0);
    }

    toggleOffCanvas = () => {
        this.state.showOffCanvasNav
            ? document.getElementsByTagName("HTML")[0].style.overflow = 'scroll'
            : document.getElementsByTagName("HTML")[0].style.overflow = 'hidden';
            
        this.setState({
            showOffCanvasNav: !this.state.showOffCanvasNav
        });

        // this.setFixedBody()
    }

    // setFixedBody = () => {
    //     if(this.state.showOffCanvasNav) {
    //         
    //     } else {
    //         
    //     }
    // }

    render() {
        return (
            <>
                <StyledNav isSticky={this.state.stickyNav}>
                    <StyledHomeButton isSticky={this.state.stickyNav}>
                        <StyledHomeLink exact to="/" onClick={this.onHomeClick}><StyledLogo isSticky={this.state.stickyNav} /></StyledHomeLink>
                    </StyledHomeButton>
                    <StyledMenuButton
                        aria="Open Menu"
                        isSticky={this.state.stickyNav}
                        onClick={this.toggleOffCanvas}
                        isShow={this.state.showOffCanvasNav}>
                    </StyledMenuButton>
                </StyledNav>
                <OffCanvasNav
                    isShow={this.state.showOffCanvasNav}
                    onRedirect={this.toggleOffCanvas}
                />

                {this.state.showOffCanvasNav && <StyledBackdrop onClick={this.toggleOffCanvas}></StyledBackdrop>}
            </>
        )
    }
}

const StyledLogo = styled(Logo)`
    transition: all .25s ease;
    width: ${props => props.isSticky ? '2rem' : '3.6rem'};
    height: ${props => props.isSticky ? '2rem' : '3.6rem'};

    @media screen and (min-width: 500px) {
        width: ${props => props.isSticky ? '3.6rem' : '5rem'};
        height: ${props => props.isSticky ? '3.6rem' : '5rem'};
    }
`;

const StyledNav = styled.nav`
    height: ${props => props.isSticky ? `4rem` : '6rem'};
    background: ${props => props.isSticky ? `${props.theme.colors.primaryDark}40` : 'transparent'};
    ${props => props.isSticky ? `box-shadow: 0 0 .2rem ${props.theme.colors.primaryDark}` : null};
    transition: background ease-in-out .25s;
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;

    @media screen and (min-width: 500px) {
        height: ${props => props.isSticky ? `6rem` : '8rem'};
    }
`;

const StyledHomeButton = styled(Button)`
    position: fixed;
    padding: 0;
    height: inherit;
    box-shadow: -.2rem 0 .2rem ${props => props.theme.colors.primaryDark};
    left: -.4rem;
    background-color: ${props => props.theme.colors.primaryDark};

    svg {
        fill: white
    }

    span {
        height: inherit;
    }

    @media screen and (min-width: 500px) {
        width: ${props => props.isSticky ? `8rem` : '12rem'};
    }
`;

const StyledHomeLink = styled(NavLink)`
    height: 100%;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    top: .5rem;
    left: .5rem;
    padding: 0 2rem;
`;

const StyledMenuButton = styled(StyledHomeButton)`
    width: 8rem;
    left: auto;
    right: -.4rem;
    transform: skew(5deg);
    background-color: ${props => props.theme.colors.primaryDark};

    span {
        transform: skew(-5deg);
        margin: 0 auto;
        position: relative;
        height: .2rem;
        width: 2.25rem;
        transition: ease-in .25s all;
        background-color: ${props => props.isShow ? 'transparent' : props.theme.colors.white};

        &::after,
        &::before {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: .2rem;
            top: 0;
            left: 0;
            background-color: ${props => props.theme.colors.white};
            transform: translateY(-.75rem);
            transition: ease-in .25s all;
        }

        &::after {
            top: auto;
            bottom: 0;
            transform: translateY(.75rem);

            ${props => props.isShow
            ? `
                transform: translateY(.75rem) rotate(-45deg);
                transform-origin: bottom left;
            `
            : null}
        }

        &::before {
            ${props => props.isShow
            ? `
                transform: translateY(-.75rem) rotate(45deg);
                transform-origin: top left;
            `
            : null}
        }
    }
`;

const StyledBackdrop = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    z-index: 8;
    cursor: pointer;
    left: 0;
`;
