import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { motion } from "framer-motion"
import styled from 'styled-components'

export default class Screen extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <StyledScreen 
                variants={screenAnimation}
                initial="hidden"
                animate="visible"
            >
                {this.props.children}
            </StyledScreen>
        )
    }
}

const StyledScreen = styled(motion.section)`
    min-height: calc(100vh - ${props => props.theme.layout.navHeight});
`;

const screenAnimation = {
    hidden: {
        opacity: 0,
        x: 0
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.05,
            when: "beforeChildren",
            staggerChildren: .07
        }
    },
};