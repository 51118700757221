import * as React from "react"

const Logo = (props) => {
    return (
        <svg {...props} viewBox="0 0 357.14 401" width="4em" height="4em">
            <title>The Pitwall Logo</title>
            <g id="prefix__Layer_2" data-name="Layer 2">
                <g id="prefix__Layer_1-2" data-name="Layer 1">
                    <path d="M40.92.5H.5V400h40V182l2-.79 219-63.73 16-49zm-.42 144.11V42l180 51.5z"  />
                    <path d="M356.5 89.5L253.81 399.49l-.34 1.01h-47.46l-.34-1.01-23.93-72.27-23.93 72.27-.34 1.01h-41.46l-.34-1.01L67 252.5h42.13l27.61 83.37 23.93-72.27 3.68-11.1h34.78l3.68 11.1v.01l23.93 72.26 3 9.06 3-9.06L317.5 78.5l39 11z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default Logo
