import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from "framer-motion"

export default class Container extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <StyledContainer
                variants={opacityAnimation}
                className={this.props.className}
                bgLight={this.props.bgLight}
                notFullHeight={this.props.notFullHeight}
            >
                <div>
                    {this.props.children}
                </div>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(motion.section)`
    width: 100%;
    padding: 12rem 0;
    background-color: ${props => props.bgLight ? props.theme.colors.grey : props.theme.colors.black};
    background-color: ${props => props.theme.colors.black};
    min-height: ${props => props.notFullHeight ? 'auto' : '100vh'};
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;

    > div {
        max-width: ${props => props.theme.layout.containerMaxWidth};
        width: 100%;
        margin: 0 auto;
        padding: 0 1.5rem;

        @media screen and (min-width: 768px) {
            padding: 0 2rem;
        }

    }
`;

const opacityAnimation = {
    hidden: { opacity: 0 },
    visible: {opacity: 1 }
};
