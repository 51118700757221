import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styled from 'styled-components'
import Container from '../../Components/Layout/Container'

import {TrackslistArray} from '../../data/tracklist';

export default class Trackslist extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            
            <Container>
                <StyledH3>Track list:</StyledH3>
                <StyledTileWrapper
                    variants={listContainer}
                    initial="hidden"
                    animate="visible"
                >
                {TrackslistArray.map(item => {
                    return (
                        <StyledTile
                            key={item.url}
                            variants={liItem}
                            url={item.url}
                        >
                            <Link to={`/tracks/${item.url}`}>
                                <span>{item.name}</span>
                            </Link>
                        </StyledTile>
                    )
                } )}
                </StyledTileWrapper>
            </Container>
        )
    }
}

const StyledH3 = styled.h3`
    margin-bottom: 7rem;
`;

const StyledTileWrapper = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const StyledTile = styled(motion.div)`
    min-width: 25rem;
    min-height: 16rem;
    margin: 0 0 2rem 0;
    background: url(${props => `/assets/tracks/${props.url}/track.png`}), #33333340;
    /* box-shadow: 0 1px 6px #ec36ad80; */
    background-position: center;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: auto 80%;
    position: relative;
    width: 90%;
    border-top: 5px solid ${props => props.theme.colors.grey};

    &:nth-of-type(even) {
        margin-left: auto;
    }

    @media screen and (min-width: 768px) {
        background-position: 0 center;
        min-width: 20rem;
        max-width: calc((100% - 4rem) / 3);
        margin: 0 0 2rem 2rem;
        height: 100%;

        &:nth-of-type(even) {
            margin-left: 2rem;
        }

        &:nth-of-type(3n + 1) {
            margin-left: 0;
        } 

        &:nth-of-type(3n) {
            margin-top: 4rem;
        }

        &:nth-of-type(3n - 1) {
            margin-top: 2rem;
        } 
    }

    @media screen and (min-width: 1400px) {
        max-width: calc((100% - 12rem) / 5);
        margin: 0 0 3rem 3rem;

        &:nth-of-type(even) {
            margin-left: 3rem;
        }

        &:nth-of-type(3n + 1) {
            margin-left: 3rem;
            margin-top: 2rem;
        }

        &:nth-of-type(3) {
            margin-top: 2rem;
        }

        &:nth-of-type(3n) {
            margin-top: 2rem;
        }

        &:first-of-type,
        &:nth-of-type(5n + 1) {
            margin-left: 0;
        }

        &:nth-of-type(5n - 2) {
            margin-top: 4rem;
        } 

        &:nth-of-type(5n - 1) {
            margin-top: 6rem;
        } 

        &:nth-of-type(5n) {
            margin-top: 8rem;
        } 

        &:nth-of-type(5n + 1) {
            margin-top: 0rem;
        } 
    }

    a {
        padding: 1rem;
        height: 100%;
        width: 100%;
        position: absolute;
        text-align: right;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-size: 2.6rem;
        font-family: 'Exo2Bold', 'Montserrat',  'Bahnschrift',  sans-serif;
        transform: border-color ease-in .2s;

        &:hover {
            border-color: ${props => props.theme.colors.primary};
        }
    }
`;

const listContainer = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.1,
            when: "beforeChildren",
            staggerChildren: 0.1
        }
    }
};

const liItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

// const TrackslistArray = [
//     {
//         name: 'Barcelona',
//         longName: 'Circuit de Barcelona - Catalunya',
//         url: 'barcelona'
//     }, 
//     {
//         name: 'Brands Hatch',
//         longName: 'Brands Hatch Circuit',
//         url: 'brands-hatch'
//     }, 
//     {
//         name: 'Hungaroring',
//         longName: 'Hungaroring Circuit',
//         url: 'hungaroring'
//     },
//     {
//         name: 'Misano',
//         longName: 'Misano World Circuit',
//         url: 'misano'
//     }, 
//     {
//         name: 'Monza',
//         longName: 'Autodromo Nazionale Monza',
//         url: 'monza'
//     }, 
//     {
//         name: 'Nuerburgring',
//         longName: 'Nuerburgring',
//         url: 'nuerburgring'
//     }, 
//     {
//         name: 'Paul Ricard',
//         longName: 'Circuit - Paul Ricard',
//         url: 'paul-ricard'
//     }, 
//     {
//         name: 'Silverstone',
//         longName: 'Silverstone',
//         url: 'silverstone'
//     }, 
//     {
//         name: 'Spa',
//         longName: 'Circuit de Spa-Francorchamps',
//         url: 'spa'
//     }, 
//     {
//         name: 'Zandvoort',
//         longName: 'Circuit Park Zandvoort',
//         url: 'zandvoort'
//     }, 
//     {
//         name: 'Zolder',
//         longName: 'Circuit Zolder',
//         url: 'zolder'
//     },
//     {
//         name: 'Bathurst',
//         longName: 'Bathurst Mount Panorama Circuit',
//         url: 'bathurst'
//     },
//     {
//         name: 'Kyalami',
//         longName: 'Kyalami Grand Prix Circuit',
//         url: 'kyalami'
//     },
//     {
//         name: 'Laguna Seca',
//         longName: 'WeatherTech Raceway Laguna Seca',
//         url: 'laguna-seca'
//     },
//     {
//         name: 'Suzuka',
//         longName: 'Suzuka Circuit',
//         url: 'suzuka'
//     }, 
// ]