import React, { Component } from 'react'
import { motion } from "framer-motion"
import styled from 'styled-components';
import Button from '../../Components/Button/Button';
import UnorderedList from '../../Components/Layout/Ul';

export default class Result extends Component {
    state = {
        activeResult: 'P 1',
        showPosition: '1',
        difficulty: '95'
    }

    showResults = () => {
        let allResults = [];

        for (let key in this.props.data[this.props.selectedClass][this.props.selectedDifficulty].results) {
            allResults.push(key)
        }

        return allResults.map(result => {
            let res = this.props.data[this.props.selectedClass][this.props.selectedDifficulty].results[result];
            return (
                <StyledResult
                    key={res.position}
                    isActive={res.position === this.state.activeResult}
                    onClick={() => this.showDifferentResult(res.position)}
                >
                    <UnorderedList>
                        <motion.li variants={opacityAnimation}>Driver name: <span>{res.driver}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Car: <span>{res.car}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Best lap: <span>{res.bestLap}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Best sector times:</motion.li>
                        <motion.li variants={opacityAnimation}>Sector 1: <span>{res.s1}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Sector 2: <span>{res.s2}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Sector 3: <span>{res.s3}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Optimal lap: <span>{res.optimalLap}</span></motion.li>
                    </UnorderedList>
                </StyledResult>
            )
        })
    }

    showPositions = () => {
        let allResults = [];

        for (let key in this.props.data[this.props.selectedClass][this.props.selectedDifficulty].results) {
            allResults.push(key)
        }

        return allResults.map(result => {
            let res = this.props.data[this.props.selectedClass][this.props.selectedDifficulty].results[result];
            return (
                <div key={res.position}>
                    <Button
                        onClick={() => this.showDifferentResult(res.position)}
                        isActive={res.position === this.state.activeResult}
                    >
                            {res.position}
                    </Button>
                </div>
            )
        })
    }

    showDifferentResult = (position) => {
        let pos = position.substring(2);
        this.setState({
            activeResult: position,
            showPosition: pos
        })
    }

    showImage = () => {
        let diff = this.props.selectedDifficulty;
        diff = diff.substr(1);

        return (
            <picture>
                <img
                    src={`/assets/tracks/${this.props.trackUrl}/images/${this.props.selectedClass}/${diff}-${this.state.showPosition}.jpg`}
                    alt={`Lap timing for driver on position ${this.state.showPosition} on ${this.props.trackLongName} track.`}
                />
            </picture>
        )
    }

    render() {

        return (
            <>
                <h5>Results</h5>
                <StyledPostionsWrapper>
                    {this.props.data && this.showPositions()}
                </StyledPostionsWrapper>
                <StyledResultWrapper>
                    {this.props.data && this.showResults()}
                </StyledResultWrapper>
                <StyledResultImg>
                        {this.props.data && this.showImage()}
                </StyledResultImg>
            </>
        )
    }
}


const StyledResultWrapper = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
`;

const StyledResult = styled.div`
    min-width: 100%;
    transition: all ease-in .2s;
    
    &:hover {
        cursor: pointer;
    }

    &:first-of-type {
        transform: translateX(${props => props.isActive ? '100%' : '200%'});
    }
    &:nth-of-type(2) {
        transform: translateX(${props => props.isActive ? '0' : '100%'});
    }
    &:last-of-type {
        transform: translateX(${props => props.isActive ? '-100%' : '0'});
    }

    @media screen and (min-width: 768px) {
        min-width:  ${props => props.isActive ? '60%' : '10%'};
        /* opacity: ${props => props.isActive ? '1' : '0.4'}; */
        border: ${props => props.isActive ? `1px solid ${props.theme.colors.primary}` : '1px solid transparent'};
        border-bottom: ${props => props.isActive ? `1px solid ${props.theme.colors.black}` : `1px solid ${props.theme.colors.primary}`};
        box-shadow: 0 0 15px rgba(0,0,0,0.6);

        &:first-of-type,
        &:last-of-type,
        &:nth-of-type(2) {
            transform: translateX(0);
            /* transform: scale(${props => props.isActive ? '1' : '0.95'}) translateX(0); */
        }
        ul {min-width: 40rem;}
    }
    @media screen and (min-width: 1024px) {
        min-width: calc(100% / 3);

        ul {
            min-width: auto;
        }
    }

    /* on > */
    /* flex: 1;
    transition: ease-in-out .15s all;
    transform: ${props => props.isActive ? 'scale(1)' : 'scale(0.95)'};
    transform: scale(1);
    padding: 2rem 0;
    padding-bottom: 4rem;
    background-color: ${props => props.theme.colors.black};
    font-size: 1.8rem;
    width: 100%;
    border: ${props => props.isActive ? `1px solid ${props.theme.colors.primary}` : '1px solid transparent'};
    border-bottom: 0; */
`;

const StyledPostionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    
`;

const StyledResultImg = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    margin-top: -1px;
    padding: 2rem;

    img {
        /* border: 1px solid ${props => props.theme.colors.red}; */
        width: 100%;
    }
`;

const opacityAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};