import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Logo from '../Logo/Logo';
import { TrackslistArray } from '../../data/tracklist';
import { Link } from 'react-router-dom';


export const OffCanvasNav = props => {
    return (
        <>
            <StyledNav
                variants={screenAnimation}
                animate={props.isShow ? 'visible' : 'hidden'}
            >
                <StyledNavInnerItem>
                    {/* <StyledLogoLink to="/"><Logo /></StyledLogoLink> */}
                    <Link to="/" onClick={props.onRedirect}>Home</Link>
                </StyledNavInnerItem>
                <StyledNavInnerItem>
                    <StyledUl
                        variants={ulAnimation}
                        animate={props.isShow ? 'visible' : 'hidden'}
                    >
                        {TrackslistArray.map(track => {
                            return (
                                <motion.li
                                    key={track.url}
                                    variants={liItem}
                                >
                                    <Link to={`/tracks/${track.url}`} onClick={props.onRedirect}>{track.name}</Link>
                                </motion.li>
                            )
                        })}
                    </StyledUl>
                </StyledNavInnerItem>
                <StyledNavInnerItem>
                    <Link to="/privacy" onClick={props.onRedirect}>Privacy</Link>
                </StyledNavInnerItem>
                <StyledNavInnerItem>
                    <StyledLogo />
                </StyledNavInnerItem>
            </StyledNav>

        </>
    )
}

const StyledNavInnerItem = styled.div`
    border-bottom: 2px solid ${props => props.theme.colors.primaryDark};
    padding: 2rem 0;
`;

const StyledUl = styled(motion.ul)``;

const StyledNav = styled(motion.div)`
    position: fixed;
    top: 0;
    left: auto;
    width: 40rem;
    max-width: 100%;
    background: ${props => props.theme.colors.black};
    right: 0;
    z-index: 9;
    padding: ${props => props.theme.layout.navHeight} 2rem 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    @media screen and (min-width: 500px) {
        padding: 7rem 5rem 5rem;
    }
`;


const StyledLogo = styled(Logo)`
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    fill: ${props => props.theme.colors.primaryDark};
    width: 4rem;
    height: 4rem;

    @media screen and (min-width: 500px) {
        width: 10rem;
        height: 10rem;
    }

`;

const screenAnimation = {
    hidden: {
        opacity: 0,
        x: 400,
        width: 0
    },
    visible: {
        opacity: 1,
        x: 0,
        width: 500
    },
};


const ulAnimation = {
    hidden: {
        opacity: 0,
        x: 0
    },
    visible: {
        opacity: 1,
        x: 0,
        // transition: {
        //     when: "beforeChildren",
        //     staggerChildren: .05
        // }
    },
};

const liItem = {
    hidden: { x: 200, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1
    }
};
