import React from 'react'
import styled from 'styled-components';
import { Component } from 'react';
import Button from '../../Components/Button/Button';

class YTVideo extends Component {
    render() {
        return (
            <StyledWrapper reversed={this.props.reversed} bottomMargin={this.props.bottomMargin}>
                <StylediFrameWrapper video={this.props.allowYT}>
                    {this.props.allowYT ?
                        <iframe
                            title="Hotlap iframe"
                            src={this.props.hotlapUrl}
                            frameborder="0"
                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>

                        : (
                            <>
                                <p>In order to perserve your privacy, the Youtube video is disabled until your consent. </p>
                                <p>You can constent to load Youtube video by simply clicking on button below.</p>
                                <Button active onClick={this.props.onAllowYT}>Allow Youtube Video</Button>

                                <p>If you allow Youtube videos, we will save your consent in cookie.</p>
                                <p>You can withdraw your consent at any time.</p>
                            </>
                        )
                    }
                </StylediFrameWrapper>
                <StyledText reversed={this.props.reversed}>
                    <h4>{this.props.class}</h4>
                    <div reversed={this.props.reversed}>
                        <p>Check this hotlap from <span>{this.props.hotlapDriver}</span> to find where you can shave a tenth or more from your lap time.</p>
                        <br />
                        <p>Feel free to check his <a href={this.props.hotlapChannel} target="_blank" rel="noopener noreferrer">channel</a>.</p>
                    </div>
                </StyledText>
            </StyledWrapper>
        )

    }
}

export default YTVideo;

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${props => props.bottomMargin ? '6rem' : null};
    padding: 5rem 0 0;

    @media screen and (min-width: 1024px) {
        flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
    }
`;

const StyledText = styled.div`
    order: -1;
    margin-bottom: 3rem;
    width: 100%;
    position: relative;
    display: flex;

    @media screen and (min-width: 1024px) {
        flex-direction: column;
    }

    & > div {
        position: relative;
        max-width: 38rem;
        
        @media screen and (min-width: 500px) {
            max-width: 50%;
            width: 50%;
            left: ${props => props.reversed ? '0' : '50%'};
        }
        
        @media screen and (min-width: 1024px) {
            max-width: 100%;
            width: 100%;
            left: 0;
            margin-left: ${props => props.reversed ? '2rem' : '0'};
        }
    }

    p {
        font-size: 2rem;
    }

    h4 {
        font-style: italic;
        opacity: .25;
        right: 0;
        position: absolute;
        font-size: 12rem;
        top: -100%;

        @media screen and (min-width: 500px) {
            opacity: .5;
            left: ${props => props.reversed ? 'auto' : '0'};
            right: ${props => props.reversed ? '0' : 'auto'};
            top: -2rem;
        }

        @media screen and (min-width: 768px) {
            font-size: 17rem;
            top: -6rem;
        }

        @media screen and (min-width: 1024px) {
            position: relative;
            left: ${props => props.reversed ? 'auto' : '-2rem'};
        }
    }
`;

const StylediFrameWrapper = styled.div`
    /* min-width: 50%;
    min-width: 650px;
    min-height: 350px; */
    width: 100%;
    background-color: ${props => props.video ? props.theme.colors.primaryDarker : props.theme.colors.grey};
    min-height: ${props => props.video ? null : '350px'};
    padding: .6rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;

    @media screen and (min-width: 1024px) {
        padding: 2rem;
        max-width: 69rem;
    }

    button {
        align-self: center;
        margin-bottom: 2rem;
    }

    iframe {
        width: 100%;
        height: calc(100vw / (16/9));
        
        @media screen and (min-width: 1024px) {
            width: 650px;
            height: 380px; 
        }
    }

`;