import React from 'react'
import styled from 'styled-components';

const ACC = props => {
    return (
        <StyledACC>
            <StyledACCText>
                <h3>Assetto Corsa Competizione</h3>
                <p>Developed by <a href="https://www.kunos-simulazioni.com/main/" target="_blank" rel="noopener noreferrer">Kunos Simulazioni</a>, Assetto Corsa Competizione is a racing simulator with officially licensed GT3 and GT4 cars.</p>
                <p>Drive against capable and challenging AI in Quick races, Sprint Race Weekend and Endurance Race Weekend. Experience firsthand what is like to drive in world famous <a href="https://www.total24hours.com/" target="_blank" rel="noopener noreferrer">Total 24 Hours of Spa</a>.

                        </p>
                <p>Challange yourself and others in casual or ranked multiplayer and improve your race craft.</p>
                <StyledACCLogo>
                    <picture>
                        <img src="/assets/logo-acc.png" alt="Assetto Corsa Competizione logo" />
                    </picture>
                </StyledACCLogo>
            </StyledACCText>
        </StyledACC>
    )
}

export default ACC;



const StyledACC = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: 0 1rem;
    }
`;

const StyledACCText = styled.div`
    background-color: rgba(0,0,0,.85);
    padding: 4rem 2rem;

    @media screen and (min-width: 768px) {
        width: 70%;
        padding: 6rem 4rem;

        h3 {
            display: block;
        }       
    }

    @media screen and (min-width: 1024px) {
        width: 100%
    }

    p {
        margin-bottom: 1.6rem;
    }
`;

const StyledACCLogo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    padding: 2rem;
    
    @media screen and (min-width: 500px) {
        margin: 8rem auto 0;
        max-width: 46.4rem;
    }

    img {
        max-width: 100%;

        @media screen and (min-width: 500px) {
            max-width: 43rem;
        }
    }

    @media screen and (min-width: 768px) {
        /* max-width: calc((100% / 3) - 3rem);
        margin-left: 2rem; */
    }
`;