import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { motion } from "framer-motion"
import styled from 'styled-components';
import Screen from '../../Components/Layout/Screen';
import Container from '../../Components/Layout/Container';
import Result from '../../Containers/Result/Result';
import Button from '../../Components/Button/Button';
import UnorderedList from '../../Components/Layout/Ul';
import Hero from '../../Components/Hero/Hero';
import Trackslist from '../../Containers/Trackslist/Trackslist';
import YTVideo from '../../Containers/YTVideo/YTVideo';
import TrackInfo from '../../Components/Track/TrackInfo';
import { Helmet } from 'react-helmet';

export default class Track extends Component {
    static propTypes = {
        prop: PropTypes
    }

    state = {
        selectedClass: 'GT3',
        selectedDifficulty: 'd95',
        allClasses: []
    };

    componentDidMount() {
        this.getData();
        this.getCookie();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.getData();
        }
    }

    /**
     * Loads the data for specific track from json file
     * and sets it to the state
     * 
     * @memberof Track
     */
    getData = () => {
        const trackURL = window.location.href;
        const trackName = new URL(trackURL).pathname.substring(8);
        window.scrollTo(0,0);
        try {
            fetch(`/assets/tracks/${trackName}/data.json`, 
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        track: data.track,
                        ai: data.AI,
                        hotlap: data.hotlap
                    })
                })
                .catch(error => {
                    this.props.history.push("/error");
                    throw(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Iterate over the object to find all classes
     *
     * @memberof Track
     */
    getAllClasses = () => {
        let allClassesArray = [];

        for (let key in this.state.ai) {
            allClassesArray.push(key)
        }

        return allClassesArray.map(item => {
            return (
                <Button
                    onClick={() => this.onSwitchClass(item)}
                    isActive={this.state.selectedClass === item}
                >
                    {item}
                </Button>
            )
        })
    }

    onSwitchClass = (i) => {
        this.setState({
            selectedClass: i
        })
    }

    getAllDifficulties = () => {
        let allDifficulties = [];

        for (let key in this.state.ai[this.state.selectedClass]) {
            allDifficulties.push(key)
        }

        return allDifficulties.map((item, i) => {
            let name = item;
            name = item.substr(1)

            return (
                <Button
                    onClick={() => this.onSwitchDifficulty(item)}
                    isActive={this.state.selectedDifficulty === item}
                >
                    {name}
                </Button>
            )
        })
    }

    onSwitchDifficulty = (i) => {
        this.setState({
            selectedDifficulty: i
        })
    }

    /**
     * Sets cookie for allowing youtube iframe
     *
     * @memberof Track
     */
    setCookie = () => {
        var date = new Date();
        date.setTime(date.getTime()+(7*24*60*60*1000));
        var expires = "; expires="+date.toUTCString();

        document.cookie = 'allowYT=true'+expires+ ';secure;path=/';

        this.setState({allowYT: true})
    }

    /**
     * Check if the cookie for youtube iframe exists
     *
     * @memberof Track
     */
    getCookie = () => {
        let cookieName = `allowYT=`;
        let cookieArray = document.cookie.split(';');

        for (let cookie of cookieArray) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(cookieName) === 0) {
                this.setState({allowYT: true});
              }
          }
    }

    render() {
        const {track, ai, hotlap}  = this.state;
        const selectedDifficulty = this.state.selectedDifficulty;
        const selectedClass = this.state.selectedClass;
        return (
            <Screen>
                <Helmet>
                    ${track ?
                        <title>{`${track && track.name} | the-Pitwall`}</title>
                        : null
                        }
                        <meta
                            name="description"
                            content={`AI ability and aggressiveness settings for the ${track && track.name} circuit on racing simulation Assetto Corsa Competizione.`}
                        />
                </Helmet>
                <Hero
                    trackUrl={track && track.url}
                    trackName={track && track.name}
                    trackLongName={track && track.longName}
                />
                <Container>
                    <TrackInfo track={track}/>
                </Container>

                <Container>
                    <h2>AI race result</h2>
                    <StyledOptions>
                        <StyledSwitcher>
                            <div>
                                <h5>Class:</h5>
                                <div>{ai && this.getAllClasses()}</div>
                            </div>
                            <div>
                                <h5>Abilty & aggression:</h5>
                                <div>{ai && this.getAllDifficulties()}</div>
                            </div>
                        </StyledSwitcher>
                        <StyledList>
                            <h5>Conditions:</h5>
                            <UnorderedList>
                                <motion.li variants={opacityAnimation}>Aggression: <span>{ai && ai[selectedClass][selectedDifficulty].aggression}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Difficulty: <span>{ai && ai[selectedClass][selectedDifficulty].difficulty}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Duration: <span>{ai && ai[selectedClass][selectedDifficulty].duration}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Total laps: <span>{ai && ai[selectedClass][selectedDifficulty].laps}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Nr of corners: <span>{track && track.turns}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Total drivers: <span>{ai && ai[selectedClass][selectedDifficulty].totalDrivers}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Weather at start: <span>{ai && ai[selectedClass][selectedDifficulty].weatherAtStart}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Track at start: <span>{ai && ai[selectedClass][selectedDifficulty].trackAtStart}</span></motion.li>
                                <motion.li variants={opacityAnimation}>Time of start: <span>{ai && ai[selectedClass][selectedDifficulty].timeOfStart}</span></motion.li>
                            </UnorderedList>
                        </StyledList>
                    </StyledOptions>
                </Container>

                
                <Container>
                    {ai &&  
                        <>
                            <Result
                                selectedClass={selectedClass}
                                selectedDifficulty={selectedDifficulty}
                                data={ai}
                                track={this.state.track.name}
                                trackUrl={this.state.track.url}
                                trackLongName={track && track.longName}
                            />
                        </>
                    }
                </Container>

                <Container>
                <h3>Hotlap</h3>
                    {hotlap &&  
                    <YTVideo
                        allowYT={this.state.allowYT}
                        hotlapDriver={hotlap.GT3.driver}
                        hotlapUrl={hotlap.GT3.url}
                        hotlapChannel={hotlap.GT3.channelUrl}
                        onAllowYT={this.setCookie}
                        class="GT3"
                        bottomMargin
                    />
                    }
                    {hotlap &&
                        hotlap.GT4 ? (
                            <YTVideo
                                allowYT={this.state.allowYT}
                                reversed
                                hotlapDriver={hotlap.GT4.driver}
                                hotlapUrl={hotlap.GT4.url}
                                hotlapChannel={hotlap.GT4.channelUrl}
                                onAllowYT={this.setCookie}
                                class="GT4"
                            />
                        )
                        : null
                    }
                </Container>

                <Trackslist />
            </Screen>
        )
    }
}

const opacityAnimation = {
    hidden: { opacity: 0 },
    visible: {opacity: 1 }
};

const StyledOptions = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
    
    /* > div {
        width: calc(50% - 1.5rem);

        &:first-of-type {
            margin-right: 3rem;
        }

        > div {
            &:not(:last-of-type) {
                margin-bottom: 3rem;
            }
        }
    } */
`;

const StyledSwitcher = styled.div`
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 500px) {
        flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: column;
        max-width: 35rem;
    }
    
    > div {

        &:first-of-type {
            margin-bottom: 1.7rem;

            @media screen and (min-width: 500px) {
                margin-bottom: 0;
                margin-right: 2rem;
            }
            @media screen and (min-width: 768px) {
                margin-right: 6rem;
            }
            @media screen and (min-width: 1024px) {
                margin-bottom: 6rem;
            }
        }

        h5 {
            font-size: 2rem;

            @media screen and (min-width: 500px) {
                font-size: 2.6rem;
            }
        }
    }
`;

const StyledList = styled.div`
    @media screen and (min-width: 1024px) {
        max-width: calc(100% - 45rem);
        width: 100%;
        margin-left: 5rem;
    }
`;