import React from 'react'
import { motion } from "framer-motion"
import styled from 'styled-components';
import UnorderedList from '../Layout/Ul';

const TrackInfo = props => {
    return (
        <>
            <h2>Track info</h2>
            <StyledConditions>
                <div>
                    <h5>Informations about track:</h5>
                    <UnorderedList>
                        <motion.li variants={opacityAnimation}>Name: <span>{props.track && props.track.longName}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Country: <span>{props.track && props.track.country}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Length: <span>{props.track && props.track.length}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Turns: <span>{props.track && props.track.turns}</span></motion.li>
                        <motion.li variants={opacityAnimation}>Record: <span>{props.track && props.track.record}</span></motion.li>
                        <motion.li variants={opacityAnimation}><span>{props.track && props.track.description}</span></motion.li>
                    </UnorderedList>
                </div>

                <StyledTrackImage>
                    <h5>Circuit map:</h5>
                    {props.track &&
                        <img src={`/assets/tracks/${props.track.url}/track.png`} alt={`Top down view of the ${props.track.longName} racing track`}  />
                    }
                </StyledTrackImage>
            </StyledConditions>
        </>
    )
}

export default TrackInfo;


const StyledConditions = styled.div`
    display: flex;
    flex-direction: column;

    ul li {
        &:first-of-type {
            text-align: right;
        }
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;

        > div {
            width: calc(50% - 4.5rem);

            &:first-of-type {
                margin-right: 3rem;
            }
        }
    }
`;

const StyledTrackImage = styled.div`
    position: relative;
    min-height: 30rem;
    margin-top: 5rem;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }

    img {
        height: auto;
        max-height: 40rem;
        position: absolute;
        left: 50%;
        top: 0px;
        transform: translate(-50%,0);
        top: 5rem;
        max-width: 100%;

        @media screen and (min-width: 768px) {
            transform: translate(-50%, -50%);
            top: 50%;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 4rem;
        }
    }
`;

const opacityAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};