import React from 'react'
import styled from 'styled-components';

const Hero = props => {
    return (
        <StyledHero landing={props.landing}>
            {props.trackUrl && <StyledBGImage trackUrl={props.trackUrl}></StyledBGImage>}
            {props.imageName && <StyledBGImageLP imageName={props.imageName}></StyledBGImageLP>}
            <StyledHeroContainer landing={props.landing}>
                <h1>{props.trackLongName ? props.trackLongName : props.headline}</h1>
            </StyledHeroContainer>
        </StyledHero>
    )
}

export default Hero

const StyledHero = styled.div`
    min-height: 30rem;
    height: 100%;
    position: relative;
    font-family: 'Exo2Bold', 'Montserrat',  'Bahnschrift',  sans-serif;

    @media screen and (min-width: 500px) {
        min-height: ${props => props.landing ? '30rem' : '45rem'};
    }
    @media screen and (min-width: 768px) {
        min-height: ${props => props.landing ? '45rem' : '60rem'};
    }
    @media screen and (min-width: 1024px) {
        min-height: ${props => props.landing ? '60rem' : '60rem'};
    }
`;

const StyledBGImageLP = styled.div`
    background-image: url(${props => `/assets/img/hero/${props.imageName}-xs.jpg`});
    min-height: inherit;
    background-position: 50% 60%;
    border: 1rem solid ${props => props.theme.colors.primaryDarker};
    border-top: 0;

    @media screen and (min-width: 501px) {
        background-image: url(${props => `/assets/img/hero/${props.imageName}-sm.jpg`});
    }
    @media screen and (min-width: 769px) {
        background-image: url(${props => `/assets/img/hero/${props.imageName}-md.jpg`});
    }
    @media screen and (min-width: 1024px) {
        background-image: url(${props => `/assets/img/hero/${props.imageName}-xl.jpg`});
    }
    @media screen and (min-width: 1921px) {
        background-image: url(${props => `/assets/img/hero/${props.imageName}.jpg`});
    }
`;

const StyledBGImage = styled.div`
    background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1-xs.jpg`});
    min-height: inherit;
    background-position: center;

    @media screen and (min-width: 501px) {
        background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1-sm.jpg`});
    }
    @media screen and (min-width: 769px) {
        background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1-md.jpg`});
    }
    @media screen and (min-width: 1024px) {
        background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1-lg.jpg`});
    }
    @media screen and (min-width: 1401px) {
        background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1-xl.jpg`});
    }
    @media screen and (min-width: 1921px) {
        background-image: url(${props => `/assets/tracks/${props.trackUrl}/track-images/1.jpg`});
    }
`;

const StyledHeroContainer = styled.div`
    max-width: 144rem;
    width: 100%;
    padding: 0 2rem;
    position: absolute;
    top: auto;
    z-index: 1;

    > h1 {
        text-shadow: 0 0 2px ${props => props.theme.colors.black};
        line-height: 0.9em;
    }

    ${props => props.landing
    ? `
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);

        > h1 {
            transform: translate(0, 50%);
            text-align: center;
            text-shadow: 0 0 .95rem ${props.theme.colors.black};

            @media screen and (min-width: 1200px) {transform: translate(0, 50%) scale(1.3);}
            @media screen and (min-width: 1920px) {transform: translate(0, 50%) scale(1.5);}
        }
    `
    : `
        left: 50%;
        bottom: 1.5rem;
        transform: translateX(-50%);
    `
    }

    
`;
