import {createGlobalStyle} from 'styled-components';

import Exo2Bold from './font/Exo2-Bold.ttf'
import Exo2Regular from './font/Exo2-Regular.ttf'
import Exo2Light from './font/Exo2-Light.ttf'

export default createGlobalStyle`

  @font-face {
      font-family: 'Exo2Bold';
      src: local('Exo2Bold'), local('Exo2Bold'),
      url(${Exo2Bold}) format('truetype');
      font-weight: 300;
      font-style: normal;
  }
  @font-face {
      font-family: 'Exo2Regular';
      src: local('Exo2Regular'), local('Exo2Regular'),
      url(${Exo2Regular}) format('truetype');
      font-weight: 300;
      font-style: normal;
  }
  @font-face {
      font-family: 'Exo2Light';
      src: local('Exo2Light'), local('Exo2Light'),
      url(${Exo2Light}) format('truetype');
      font-weight: 300;
      font-style: normal;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    font-weight: 300;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.8rem;
    overflow-x: hidden;
    font-family: 'Exo2Light', 'Montserrat',  'Bahnschrift',  sans-serif;
    scroll-behavior: smooth;

    @media screen and (min-width: 1921px) {
      font-size: 2.4rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Exo2Bold', 'Montserrat',  'Bahnschrift',  sans-serif;
    margin-bottom: 1.6rem;
  }
  
  h6 {
    font-size: 2rem;

    @media screen and (min-width: 500px) {
      font-size: 2.2rem;
    }
  }

  h5 {
    font-size: 2.2rem;

    @media screen and (min-width: 500px) {
      font-size: 2.6rem;
    }
  }

  h4 {
    font-size: 2.6rem;

    @media screen and (min-width: 500px) {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 3.2rem;

    @media screen and (min-width: 500px) {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 4rem;

    @media screen and (min-width: 500px) {
      font-size: 5rem;
    }
  }

  h1 {
    font-size: 5.2rem;

    @media screen and (min-width: 500px) {
      font-size: 6.2rem;
    }
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: ease-in-out all .15s;

    &:hover {
        color: ${props => props.theme.colors.white};
    }
  }

  ul {
    list-style: none;
  }

  p,
  a,
  span {
    line-height: 1.35em;
  }
`
