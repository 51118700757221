import React, { Component } from 'react'
import Screen from '../../Components/Layout/Screen'
import Container from '../../Components/Layout/Container'
import Trackslist from '../../Containers/Trackslist/Trackslist'
import { TileWrapper } from '../../Components/Tile/TileWrapper'
import { Tile } from '../../Components/Tile/Tile'
import Hero from '../../Components/Hero/Hero'
import ACC from '../../Components/ACC/ACC'

export default class Landing extends Component {
    render() {
        return (
            <Screen>
                <Hero
                    landing 
                    headline="Find challenging AI settings"
                    imageName='porsche-pit'
                />
                <Trackslist />

                <Container>
                    <TileWrapper
                        imageName="20200803213130_1"
                        imageFirst
                        imgAltTag="Closeup of red Audi R8 LMS Evo lights in front of other GT3 cars in a curve."
                    >
                        <Tile>
                            <header><h3>Lap times</h3></header>
                            <div>
                                <p>Want a challenging race with AI, but don't know which ability and aggression settings to use?</p>
                                <p>Find out here what lap times does AI make on all tracks.</p>
                            </div>
                        </Tile>
                        <Tile>
                            <header><h3>Track guide</h3></header>
                            <div>
                                <p>Do you want to shave a tenth or a couple of seconds around the track?</p>
                                <p>Look at the hotlaps by <a href="https://www.youtube.com/channel/UCDscumQmDSkqPTOFU3dBfSg" target="_blank" rel="noopener noreferrer"> Tortellini</a> and find where you can improve your track times.</p>
                            </div>
                        </Tile> 
                        <Tile>
                            <header><h3>IGT &amp; GT4</h3></header>
                            <div>
                                <p>Both the International GT and GT4 DLC available.</p>
                                <p>Race against AI on some of world most <a href="https://www.assettocorsa.net/competizione/dlc/the-intercontinental-gt-pack/" target="_blank" rel="noopener noreferrer">iconic tracks</a> in exciting, performant and awesome <a href="https://www.assettocorsa.net/competizione/dlc/the-gt4-pack-dlc/" target="_blank" rel="noopener noreferrer">GT4</a> cars.</p>
                            </div>
                        </Tile> 
                        <Tile>
                            <header><h3>Just enjoy</h3></header>
                            <div>
                                <p>The more laps you put in, the faster you will be. </p>
                                <p>Just enjoy driving this phenomenal, challenging, realistic and beautiful simulation.</p>
                            </div>
                        </Tile> 
                    </TileWrapper>
                </Container>
                <Container>
                    <TileWrapper
                        imageName="20200803213435_1"
                        imgAltTag="Pirelli tire on white Ferrari 458 GT3 under the Assetto Corsa Competizione board."
                    >
                        <ACC />
                    </TileWrapper>
                </Container>
            </Screen>
        )
    }
}