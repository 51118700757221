import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Layout/Screen'
import Container from '../../Components/Layout/Container'
import styled from 'styled-components'
import Button from '../../Components/Button/Button'
import { Helmet } from 'react-helmet'

export default class Privacy extends Component {
    static propTypes = {
        prop: PropTypes
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 50);
    }

    onWithdrawConsent = () => {
        let cookieName = `allowYT`;
        let cookieArray = document.cookie.split(';');

        for (var i = 0; i < cookieArray.length; i++) {
            document.cookie = cookieName + '=; Max-Age=0; path=/;';
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Privacy | the-Pitwall</title>
                </Helmet>
                <Screen>
                <Container>
                    <StyledPrivacyContainer>
                        <h1>Privacy</h1>
                        <p>Last updated: August 19, 2020</p>

                        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. This Privacy Policy is maintained by the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/">Free Privacy Policy Generator</a>.</p>


                        <h2>Tracking Technologies and Cookies</h2>
                        <p>For the better usability on the "track" page, if you allow Youtube video to load, we will store that consent into cookie.</p>
                        <p>You can withdraw your consent at any time by clicking on this button: <Button onClick={this.onWithdrawConsent}>Withdraw consent</Button> </p>
                        <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
                        <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies here: <a href="https://www.freeprivacypolicy.com/blog/cookies/">Cookies: What Do They Do?</a></p>

                        <h2>Links to Other Websites</h2>
                        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                        <h2>Changes to this Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time.</p>
                        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                        <h2>Contact Us</h2>

                        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul>
                            <li>By email: thodak.dev@gmail.com</li>
                        </ul>

                    </StyledPrivacyContainer>
                </Container>
            </Screen>
            </>
        )
    }
}

const StyledPrivacyContainer = styled.div`
    max-width: 80rem;
    margin: 0 auto;

    p {
        margin-bottom: 1.5rem;
    }

    h1 {
        margin-bottom: 8rem;
    }

    h2 {
        margin-top: 4rem;
        font-size: 2.8rem;
    }
`;
