import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './Components/GlobalStyles/Theme';
import GlobalStyle from './Components/GlobalStyles/GlobalStyles'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import Landing from './Screens/Landing/Landing';
import Tracks from './Screens/Tracks/Tracks';
import Nav from './Containers/Nav/Nav';
import Track from './Screens/Track/Track';
import Footer from './Components/Layout/Footer';
import { Helmet } from 'react-helmet';
import { Error404 } from './Screens/Error404/Error404';
import Privacy from './Screens/Privacy/Privacy';

class App extends Component {
  componentDidMount() {
    console.log(`%c Hello there!
    
    If you find something wrong or have something to 
    add, don't hesitate to contact me:

    Via mail:   support@the-pitwall.com
    Twitter:    https://twitter.com/tomislav0401
    `, 'background: #222; color: #ec36ad');

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>the-Pitwall</title>
          <meta
            name="description"
            content="Find out settings for the AI ability and aggressiveness needed to have a challenging single player race in Assetto Corsa Competizione."
          />
        </Helmet>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
              <GlobalStyle />
              <StyledWrapper>
                <Nav />
                <Switch>
                  <Route path='/' exact component={Landing} />
                  <Route path='/tracks' exact component={Tracks} />
                  <Route path='/tracks/:id' component={Track} />
                  <Route path='/error' exact component={Error404} /> 
                  <Route path='/privacy' exact component={Privacy} /> 
                  <Route path=''><Redirect to="/error" /></Route> 
                </Switch>
                <Footer />
              </StyledWrapper>
          </ThemeProvider>
        </BrowserRouter>
      </>
    );
  }
}  

export default App;

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  min-height: 100vh;
`;