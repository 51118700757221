import React from 'react'
import Container from './Container'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../Components/Logo/Logo'

const Footer = props => {
    return (
        <StyledFooter>
            <StyleContainer notFullHeight>
                <StyledFooterInner>
                    <Logo />
                    <StyledTitle>the-pitwall</StyledTitle>

                    <StyledFooterLinks>
                        <Link to="/">Home</Link>
                        <Link to="/privacy">Privacy</Link>
                    </StyledFooterLinks>

                    <StyledInfo>
                        <p>All images were taken in game. The graphics are beautiful enough, so the only editing done is cropping and size change.</p>
                    </StyledInfo>
                </StyledFooterInner>
            </StyleContainer>
        </StyledFooter>
    )
}


export default Footer;

const StyleContainer = styled(Container)`
    padding: 3rem 0;
`;

const StyledFooter = styled.footer`
    padding: 4rem 0;
    text-align: center;
    border-top: 1px solid ${props => props.theme.colors.primary};
`;

const StyledFooterInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        fill: ${props => props.theme.colors.primary};
        height: 10rem;
        width: 10rem;
        margin-bottom: 2rem;
    }
`;

const StyledFooterLinks = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 6rem;
    
    > * {
        margin: 0 3rem;
    } 
`;

const StyledTitle = styled.p`
    font-size: 2.4rem;
    font-family: 'Exo2Bold', 'Montserrat',  'Bahnschrift',  sans-serif;
    margin: 1.6rem 0;
`;

const StyledInfo = styled.div`
    margin-top: 6rem;

    p {
        font-size: 16px;
    }
`;