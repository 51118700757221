
export const TrackslistArray = [
    {
        name: 'Barcelona',
        longName: 'Circuit de Barcelona - Catalunya',
        url: 'barcelona'
    }, 
    {
        name: 'Brands Hatch',
        longName: 'Brands Hatch Circuit',
        url: 'brands-hatch'
    }, 
    {
        name: 'Hungaroring',
        longName: 'Hungaroring Circuit',
        url: 'hungaroring'
    },
    {
        name: 'Misano',
        longName: 'Misano World Circuit',
        url: 'misano'
    }, 
    {
        name: 'Monza',
        longName: 'Autodromo Nazionale Monza',
        url: 'monza'
    }, 
    {
        name: 'Nuerburgring',
        longName: 'Nuerburgring',
        url: 'nuerburgring'
    }, 
    {
        name: 'Paul Ricard',
        longName: 'Circuit - Paul Ricard',
        url: 'paul-ricard'
    }, 
    {
        name: 'Silverstone',
        longName: 'Silverstone',
        url: 'silverstone'
    }, 
    {
        name: 'Spa',
        longName: 'Circuit de Spa-Francorchamps',
        url: 'spa'
    }, 
    {
        name: 'Zandvoort',
        longName: 'Circuit Park Zandvoort',
        url: 'zandvoort'
    }, 
    {
        name: 'Zolder',
        longName: 'Circuit Zolder',
        url: 'zolder'
    },
    {
        name: 'Bathurst',
        longName: 'Bathurst Mount Panorama Circuit',
        url: 'bathurst'
    },
    {
        name: 'Kyalami',
        longName: 'Kyalami Grand Prix Circuit',
        url: 'kyalami'
    },
    {
        name: 'Laguna Seca',
        longName: 'WeatherTech Raceway Laguna Seca',
        url: 'laguna-seca'
    },
    {
        name: 'Suzuka',
        longName: 'Suzuka Circuit',
        url: 'suzuka'
    }, 
]