import React from 'react'
import styled from 'styled-components';

function Button(props) {
    return (
        <StyledButton
            onClick={props.onClick}
            isActive={props.isActive}
            className={props.className}
            aria-label={props.aria}
            name={props.aria}
        >
            <span>{props.children}</span>
        </StyledButton>
    )
}

Button.propTypes = {

}

export default Button;

const StyledButton = styled.button`
    outline: none;
    border: 0;
    padding: 1.2rem 3.2rem;
    cursor: pointer;
    transition: ease-in-out all .15s;
    color: ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.white};
    background-color: ${props => props.isActive ? props.theme.colors.white : props.theme.colors.primary};

    color: ${props => props.theme.colors.white};
    background-color: ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.darkGrey};
    font-size: 2rem;
    transform: skew(-5deg);

    > span {
        transform: skew(5deg);
        display: block;
    }
`;