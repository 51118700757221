import React from 'react'
import styled from 'styled-components';

export const Tile = props => {
    return (
        <StyledTile>
            {props.children}
        </StyledTile>
    )
}



const StyledTile = styled.article`
    background: rgba(0,0,0,0.85);
    padding: 2rem;
    width: 100%;
    margin: 0 0 2rem 2rem;
    box-shadow: 0 0 10px -10px ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 2rem);
    transform: translateY(0);

    &:nth-of-type(3),
    &:first-of-type {
        margin-left: 0;
    }

    @media screen and (min-width: 500px) {
        margin-bottom: 4rem;
        padding: 4rem;

        &:last-of-type {
            margin-bottom: 2rem;
        }

        h3 {
            margin-bottom: 2.8rem;
        }
    }

    @media screen and (min-width: 1024px) {
        width: 100%;
        max-width: calc((100% - 6rem) / 2); 
        margin-bottom: 3rem;
        margin-left: 0;
        text-align: center;

        &:nth-of-type(2),
        &:last-of-type {
            transform: translateY(3rem);
            margin-left: 3rem;
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 4rem;
    }
`;