import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion"


export const TileWrapper = props => {
    return (
        <StyledTileWrapper>
            <TileImage imageFirst={props.imageFirst}>
                <picture>
                    <img src={`/assets/img/${props.imageName}.jpg`} alt={props.imgAltTag} />
                </picture>
            </TileImage>
            <TileInner imageFirst={props.imageFirst}>
                {props.children}
            </TileInner>
        </StyledTileWrapper>
    )
}

const StyledTileWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
        flex-direction: row;

        h3 {
            font-size: 4rem;
        }
    }

    @media screen and (min-width: 1920px) {
        /* position: relative; */
    }
`;

const TileImage = styled.div`
    max-width: 80%;
    height: calc(100% - 20rem);
    width: 100%;
    left: 0;
    top: 10rem;
    position: absolute;
    overflow: hidden;
    max-width: 140rem;

    @media screen and (min-width: 768px) {
        border: 2rem solid ${props => props.theme.colors.primaryDarker};
        height: 70%;
        top: 50%;
        transform: translateY(-50%);
    }
    @media screen and (min-width: 1200px) {
        border: 3rem solid ${props => props.theme.colors.primaryDarker};
        left: ${props => props.imageFirst ? '0' : 'auto'};
        right: ${props => props.imageFirst ? 'auto' : '0'};
    }

    @media screen and (min-width: 1920px) {
        left: ${props => props.imageFirst ? '40%' : 'auto'};
        right: ${props => props.imageFirst ? 'auto' : '40%'};
        transform: translate(${props => props.imageFirst ? '-50%' : '50%'}, -50%);
    }

    img {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const TileInner = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 1;

    @media screen and (min-width: 1024px) {
        min-width: 70rem;
        flex-direction: row;
        max-width: 65%;
        ${props => props.imageFirst ? 'margin-left: auto' : 'margin-right: auto'};
        justify-content: ${props => props.imageFirst ? 'flex-end' : 'flex-start'};
    }
`;