export const theme = {
    colors: {
        black: '#151515',
        darkGrey: '#2b2b2b',
        grey: '#626262',
        white: '#ededed',
        primary: '#ec36ad',
        primaryDark: '#861b60',
        primaryDarker: '#400d2e',
        red: '#d52222',
        darkRed: '#770606',
        blue: '#2c1bf4'
    },
    layout: {
        navHeight: '70px',
        containerMaxWidth: '144rem'
    }
};