import React from 'react'
import styled from 'styled-components';
import { motion } from 'framer-motion';

function UnorderedList(props) {
    return (
        <StyledUl
            variants={screenAnimation}
            initial="hidden"
            animate="visible"
        >
            {props.children}
        </StyledUl>
    )
}

export default UnorderedList;

const StyledUl = styled(motion.ul)`
    li {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 2rem;
        line-height: 2em;

        &:nth-of-type(even) {
            background-color: #1d1d1d
        }
        &:nth-of-type(odd) {
            background-color: ${props => props.theme.colors.black}
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
`;

const screenAnimation = {
    hidden: {
        opacity: 0,
        x: 0
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            delay: .05,
            when: "beforeChildren",
            staggerChildren: .10
        }
    },
};