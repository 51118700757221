import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Trackslist from '../../Containers/Trackslist/Trackslist'
import Screen from '../../Components/Layout/Screen'

export default class Tracks extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <Screen>
                <Trackslist />
            </Screen>
        )
    }
}