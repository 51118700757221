import React from 'react';
import styled from 'styled-components';
import Screen from '../../Components/Layout/Screen';
import Container from '../../Components/Layout/Container';
import Trackslist from '../../Containers/Trackslist/Trackslist';

export const Error404 = () => {
    return (
        <Screen>
            <Container notFullHeight>
                <Styled404>
                    <h1>404</h1>
                </Styled404>
            </Container>
            <Trackslist />
        </Screen>
    )
}

const Styled404 = styled.div`
    min-height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;